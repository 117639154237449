import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql } from "gatsby";


function Layout({ children }) {
  return (
      <>
      {children}
      </>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
