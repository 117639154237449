import React from "react";

import SEO from '../components/seo'
import Layout from '../components/layout'

import floatVideo from '../images/Float.mp4'
import floatVideoAlt from '../images/Float.webm'
import logo from '../images/logo_new.svg'
import container from '../images/container.png'

export default class IndexPage extends React.Component {
  steps = [
    'Borrower fills out form stating the loan amount and duration',
    'Lenders review marketplace of requested loans, assess risk factors, and offer the loan + repayment structure',
    "Loan contract initiated. Fee's paid back after last pay period.",
  ]
  
  render() {
    return (
      <Layout>
        <SEO 
          title="Landing | Float"
          description="Peer To Peer Lending Made Easy"
          lang="en"
          keywords={['peer to peer loan', 'peer to peer lending', 'p2p loans', 'p2p lending', 'loans', 'lending', 'easy loans', 'easy lending']} 
        />
        <div className="w-full h-full flex flex-col sm:flex-row">
          <div id="content" className="pt-12 flex justify-start w-full  lg:w-1/2">
            <div className=" w-full min-h-full flex flex-col justify-center items-center mx-4">
              <div className="w-full max-w-md lg:max-w-lg">
                <img src={logo} className="w-48" alt="Logo" />
                <h1 className="mt-12 text-2xl lg:text-4xl max-w-sm font-bold text-gray-800">
                  Peer To Peer Lending Made Easy
                </h1>
                <p className="text-lg font-base text-gray-600 leading-relaxed pt-4">
                  Float provides a seamless lender / borrower experience through 21st century technology.
                </p>
                <form className="py-6" action="https://floatloans.us20.list-manage.com/subscribe/post" method="POST">
                  <input type="hidden" name="u" value="4b41be1be196167ef631ecfd7" />
                  <input type="hidden" name="id" value="79e2e58493" />
                  <p className="text-center text-gray-700 font-bold">Be the first to know when we launch! <br />Join the
                    waiting list today.</p>
                  <input type="text" name="MERGE1" placeholder="First Name"
                    className="border-white border-2 p-4 w-full rounded-full my-2 mt-6" />
                  <input type="text" name="MERGE2" placeholder="Last Name"
                    className="border-white border-2 p-4 w-full rounded-full my-2" />
                  <input type="text" name="MERGE0" placeholder="Email"
                    className="border-white border-2 p-4 w-full rounded-full my-2" />
                  <input type="hidden" name="ht"
                    value="fe90e979a95b3e16511df7ee65cb52a71f3f1181:MTU2NDUwODM1OC4zMzU3" />
                  <input type="hidden" name="mc_signupsource" value="hosted" />
                  <div className="text-right  my-6">
                    <input className="btn" type="submit" value="Sign up" />
                  </div>
                </form>

              </div>
            </div>
          </div>
          <div id="media" className="flex justify-start w-full h-full lg:h-screen py-12 px-6 lg:w-1/2 bg-blue-400">
            <div className=" w-full flex justify-center items-center mx-4">
              <div className=" w-full max-w-xl ">
                  <div className="mb-12">
                   {/*  <video playsInline="true" loop controls="true" className="">
                        <source src={floatVideo} type="video/mp4"/>
                        <source src={floatVideoAlt} type="video/webm"/>
                      </video> */}
                      
                      <div className="video-container ">
                        <iframe className="rounded-lg  shadow-2xl mt-6 w-full h-64" src="https://www.youtube.com/embed/HcpedMCUfhc?vq=hd1080&theme=light&fs=0&color=white&controls=0&disablekb=1" width="560" height="315" frameborder="0"></iframe>
                      </div>
                  </div>
                <div className="flex flex-col justify-around">
                  {
                  this. steps.map((step, i) => {
                  return (
                  <div className="flex flex-col lg:flex-row items-center" key={i}>
                    <div
                      className="flex text-2xl border-2 border-fg p-6 rounded-full w-12 h-12 items-center justify-center text-a2 font-bold my-6">
                      { i + 1}
                    </div>
                    <p className="text-center  lg:text-left ml-4 text-a2 font-bold text-lg">
                      {step}
                    </p>
                  </div>
                  )
                  })
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      </Layout>
        );
      }  
    }
    
    